import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { Grid, Stack } from "@mui/material";
import MailIcons from "../images/mail-icon.svg";
import LocationIcon from "../images/location-icon.svg";
import PhoneIcon from "../images/phone-icon.svg";
import Navbar from "./layout/Navbar";
import DashboardOnboarding from "./DashboardOnboarding";
import {
  DASHBOARD_ORGANIZATION_LIST,
  GET_ORGANIZATION_DETAILS,
} from "./common/ApiUrl";
import API from "./common/API";
import {
  CustomMessage,
  CustomMessageToast,
  authHeader,
  getOrgId,
  getSideFlag,
} from "./common/mainfunctions";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import BotAppPermission from "./organizationSetting/Permissions/BotAppPermission";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../redux/action";
import BotIntegration from "./organizationSetting/Integration/BotIntegration";
export default function Dashboard() {
  const theme = useTheme();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [mobFlag, setMobFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgList, setOrgList] = useState();
  const [details, setDetails] = useState();
  const [botFlag, setBotFlag] = useState(false);
  const [code, setCode] = useState();
  const Navigation = useNavigate();
  const organizationId = getOrgId();
  console.log(organizationId, "oid");
  const userDet = useSelector((ud) => ud.UserDetailsReducer);
  const {
    msClientId = "",
    permissionUpdatedFlag = 3,
    botAppInstallFlag,
  } = userDet || {};
  const [appFlag, setAppFlag] = useState(false);
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);
  const tokens = queryParams.get("tokens");
  const cd = tokens?.split("::")[1];

  const type = queryParams.get("type");
  if (tokens && type != "accepted") {
    // Store token/code and update state
    localStorage.setItem("CODE", cd);
    // CustomMessageToast(`code das ${cd}`, "error", enqueueSnackbar);
  }
  console.log(tokens, "get token");
  console.log(type, "get type");

  // CustomMessageToast(`l${window.location.href}`, "error", enqueueSnackbar);
  const tokn = getCookie("mobileToken"); // Replace with actual token validation
  // CustomMessageToast(`Flgggggg ${permissionUpdatedFlag}`, "error", enqueueSnackbar);

  const hclose = () => {};
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);
  useEffect(() => {
    if ((mobFlag == true || isMobile() == true) && tokens) {
      // Extract and decode the token
      const receivedToken = tokens.split("::")[0];
      const base64Url = receivedToken.split(".")[1];
      const cd = tokens.split("::")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decodedData = JSON.parse(atob(base64));
      const userDisplayName = decodedData.preferred_username;
      const tid = decodedData.tid;

      // Store token/code and update state
      localStorage.setItem("CODE", cd);
      setCode(cd);
      // CustomMessage(`get success code${cd}`, "success", enqueueSnackbar);

      // setAppIdFlag(true);
      // setLoading(true);

      // CustomMessage("successfully landed", "success", enqueueSnackbar);
    }
  }, [tokens]);
  useEffect(() => {
    if (isMobile() && permissionUpdatedFlag == 0) {
      // CustomMessage("have auth", "success", enqueueSnackbar);

      setBotFlag(true);
    } else if (permissionUpdatedFlag == 1) {
      setAppFlag(true);
    }
  }, [type, permissionUpdatedFlag]);
  const getOrganizationList = async () => {
    setLoading(true);
    const config = {
      method: "GET",
      url: DASHBOARD_ORGANIZATION_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setOrgList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleBotClose = () => {
    setAppFlag(false);
    dispatch(getUserDetails());
  };
  function getCookie(name) {
    console.log(name, document.cookie);
    const value = `; ${document.cookie}`;
    console.log(value);
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  useEffect(() => {
    const token = getCookie("userToken");
    console.log("from cookie token", token);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      getOrganizationList();
    }, 500);
  }, []);
  const getOrganizationDetails = async () => {
    const config = {
      method: "GET",
      url: GET_ORGANIZATION_DETAILS,
      headers: authHeader(),
      params: {
        organizationId: organizationId,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        const { orgDetails = [] } = data;

        setDetails(orgDetails);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
    }
  };
  useEffect(() => {
    if (organizationId) {
      getOrganizationDetails();
    }
  }, [organizationId]);

  return (
    <>
      <Box sx={{ display: "-webkit-box" }}>
        {getSideFlag() == "true" ? <></> : <Navbar name="Dashboard" />}
        {/* {getSideFlag() == "true" ? "0px" : "65px"} */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: getSideFlag() === "true" ? "0px" : "65px",
            marginTop: { xs: "25px", md: "35px" },
          }}
        >
          <Box className="address-box" sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={9}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box className="logo-box">
                    <img
                      className="dash-logo"
                      src={details && details.profilePath}
                      style={{ width: "-webkit-fill-available" }}
                    ></img>
                  </Box>
                  <Box>
                    <Typography className="dash-head">
                      {details && details.name}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={LocationIcon} />
                      <Typography className="address-text" variant="body2">
                        {details && details.address}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={MailIcons} />
                      <Typography className="address-text" variant="body2">
                        {details && details.contactEmail}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={PhoneIcon} />
                      <Typography className="address-text" variant="body2">
                        {details && details.contactNumber}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              <Grid sx={{ mt: "auto" }} item xs>
                <Box sx={{ textAlign: "right" }}></Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: "100%" }}>
            <DashboardOnboarding />
          </Box>
        </Box>
      </Box>
      {botFlag && permissionUpdatedFlag == 0 && (
        <BotAppPermission
          open={botFlag}
          handleClose={hclose}
          code={code}
          appPer={true}
          botFlag={botFlag}
          setBotFlag={setBotFlag}
        />
      )}
      {permissionUpdatedFlag == 1 &&
        code &&
        botAppInstallFlag ==
          0(
            appFlag && (
              <BotIntegration
                open={appFlag}
                handleClose={handleBotClose}
                cd={code}
              />
            )
          )}
    </>
  );
}
