import React, { useEffect } from "react";
import Navbar from "../../layout/Navbar";
import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import LocationIcon from "../../../images/location-icon.svg";
import MailIcons from "../../../images/mail-icon.svg";
import PhoneIcon from "../../../images/phone-icon.svg";
import IntegrationList from "../IntegrationList";
import { useNavigate } from "react-router-dom";
import NotifiationList from "../NotificationList";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../redux/action";
import { useState } from "react";
import { GET_ORGANIZATION_DETAILS } from "../../common/ApiUrl";
import {
  CustomMessage,
  authHeader,
  getDomain,
  getRole,
  getSideFlag,
} from "../../common/mainfunctions";
import API from "../../common/API";
import { useSnackbar } from "notistack";
import AzurePermission from "../Permissions/AzurePermission";
import DeviceSetting from "./DeviceSetting";

function TabPanel({ children, value, index, other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
export default function OneOrganizationIndex() {
  const [value, setValue] = React.useState(2);
  const userDet = useSelector((sel) => sel.UserDetailsReducer);
  const {
    organizationId,
    msIntegrateFlag = "",
    disconnectFlag = "",
    permissionAddedFlag = "",
    powershellPermissionFlag = "",
    botAppInstallFlag = "",
  } = userDet || {};
  const [details, setDetails] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const tok = authHeader();
  const navigation = useNavigate();
  let domain = getDomain();

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    if (newValue != 2 && msIntegrateFlag == 0 && getRole() == "Admin") {
      CustomMessage("Azure connection is required", "error", enqueueSnackbar);
    } else {
      setValue(newValue);
    }
  };
  const viewOrgDetails = () => {
    if (
      msIntegrateFlag == 0 ||
      permissionAddedFlag == null ||
      permissionAddedFlag == 0 ||
      powershellPermissionFlag == 0 ||
      (powershellPermissionFlag == null && getRole() == "Admin") ||
      botAppInstallFlag != true ||
      botAppInstallFlag == 0
    ) {
      CustomMessage(
        "Azure connection/permission is required",
        "error",
        enqueueSnackbar
      );
    } else {
      if (organizationId) {
        navigation("/organization-details", { state: { eid: organizationId } });
      } else {
        navigation("/organization-edit", { state: { eid: "" } });
      }
    }
  };
  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  useEffect(() => {
    if (!tok) {
      navigation("/signin");
    } else {
      console.log("tok", `*****${tok}`);
    }
  }, [tok]);
  const getOrganizationDetails = async () => {
    const config = {
      method: "GET",
      url: GET_ORGANIZATION_DETAILS,
      headers: authHeader(),
      params: {
        organizationId: organizationId,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        const { orgDetails = [] } = data;

        setDetails(orgDetails);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
    }
  };
  useEffect(() => {
    if (organizationId) {
      getOrganizationDetails();
    }
  }, [organizationId]);

  return (
    <>
      <Box sx={{ display: "-webkit-box" }}>
        {getSideFlag() == "true" ? (
          <></>
        ) : (
          <Navbar name="Organization Settings" />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: getSideFlag() === "true" ? "0px" : "65px",
            pt: "15px",
          }}
        >
          <Box className="address-box" sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} md={9}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box className="logo-box">
                    {/* <img src={Logo}></img> */}
                    <img
                      className="profile-img"
                      src={details && details.profilePath}
                      style={{ width: "-webkit-fill-available" }}
                    ></img>
                  </Box>
                  <Box>
                    <Typography className="org-head">
                      {details && details.name}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={LocationIcon} />
                      <Typography className="address-text" variant="body2">
                        {/* 36811 Allder School Road, Purcellville, VA 20132 */}
                        {details && details.address}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={MailIcons} />
                      <Typography className="address-text" variant="body2">
                        {details && details.contactEmail}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <img src={PhoneIcon} />
                      <Typography className="address-text" variant="body2">
                        + {details && details.contactNumber}
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>
              <Grid sx={{ mt: "auto" }} item xs>
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    className="switch"
                    variant="contained"
                    onClick={viewOrgDetails}
                  >
                    <Typography className="org-view">View Details</Typography>{" "}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              maxWidth: { xs: 270, sm: 620, md: "100%" },
              width: "100%",
              mt: "20px",
            }}
          >
            <Tabs
              className="tabs"
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="secondary tabs example"
            >
              <Tab value={1} label="NOTIFICATIONS" />
              <Tab value={2} label="INTEGRATIONS" />
              <Tab value={3} label="PERMISSIONS" />
              {/* <Tab value={4} label="DEVICE SETTING" /> */}
            </Tabs>
          </Box>
          <TabPanel value={1} index={value}>
            <NotifiationList />
          </TabPanel>
          <TabPanel value={2} index={value}>
            <IntegrationList />
          </TabPanel>
          <TabPanel value={3} index={value}>
            <AzurePermission />
          </TabPanel>
          <TabPanel value={4} index={value}>
            <DeviceSetting />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}
