import React, { useEffect, useState } from "react";
import {
  BOT_OBJECTID_STORE,
  PERMISSIONN_FLAG_UPDATE_URL,
  PERMISSION_URL,
  USER_PROFILE_URL,
} from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  Typography,
  alpha,
} from "@mui/material";
import BitMapPax from "../../../images/Bitmap.png";
import CustomLoader from "../../common/CustomLoader";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import entraid from "../../../images/menid.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../redux/action";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(10),
  },
  "& .MuiInputBase-input": {
    borderRadius: "0px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    marginTop: "15px !important",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  appObjectId: "",
};
export default function BotAppPermission(props) {
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  let cliendid = process.env.REACT_APP_CLIENT_ID;
  const userDet = useSelector((ud) => ud.UserDetailsReducer);
  const { msClientId = "", permissionUpdatedFlag = 3 } = userDet || {};

  const [loading, setLoading] = useState(false);
  const [mobFlag, setMobFlag] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [appPer,setAppPer]=useState(false)
  const {
    open,
    handleClose,
    azureFlag,
    setAzureFlag,
    code,
    appPer,
    botFlag,
    setBotFlag,
  } = props;
  const dispatch = useDispatch();
  // CustomMessage('called app permission', "success", enqueueSnackbar);

  console.log("code", code);
  const validationSchema = Yup.object({
    appObjectId: Yup.string().trim().required("Object Id is required"),
  });
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      permissionFun(values);
    },
  });
  const isInTeams = () => {
    return window.self !== window.top;
  };
  const flagUpdate = async () => {
    const config = {
      method: "POST",
      url: PERMISSIONN_FLAG_UPDATE_URL,
      headers: authHeader(),
      data: {
        permissionAddedFlag: null,
        powershellPermissionFlag: null,
        permissionUpdatedFlag: "1",
      },
    };
    try {
      const response = await API(config);
      const { status, data, message } = response.data;
      console.log("mes", message);
      if (status == "Success") {
        const { permissionAddedFlag } = data;
        CustomMessage(message, "success", enqueueSnackbar);
        // setAzureFlag(permissionAddedFlag);
        setBotFlag(false);
        dispatch(getUserDetails());
        handleClose();
      }

      console.log("worked1");
    } catch (error) {
      console.error(error);
    }
  };
  const getDetails = async () => {
    // CustomMessage('called user', "success", enqueueSnackbar);

    const config = {
      method: "GET",
      url: USER_PROFILE_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status, data, message } = response.data;
      console.log("mes", message);
      if (status == "Success") {
        const { msClientId } = data;
        // CustomMessage(msClientId, "success", enqueueSnackbar);
        // setAzureFlag(permissionAddedFlag);
        // dispatch(getUserDetails());
        // handleClose();
        permissionFun(msClientId);
      }
      console.log("worked1");
    } catch (error) {
      console.error(error);
    }
  };
  const botUpdate = async () => {
    // CustomMessage(`${localStorage.getItem('CODE')}`, "error", enqueueSnackbar);
    // CustomMessage(`called${code}`, "error", enqueueSnackbar);
    // console.log('code inside',localStorage.getItem('CODE'))
    const config = {
      method: "POST",
      url: BOT_OBJECTID_STORE,
      headers: authHeader(),
      data: {
        tokenCode: localStorage.getItem("CODE"),
      },
    };
    try {
      const response = await API(config);
      const { status, data, message } = response.data;
      console.log("mes", message);
      if (status == "Success") {
        flagUpdate();
      }
      console.log("worked1");
    } catch (error) {
      console.error(error);
      if (error.response) {
        const { message } = error.response.data;

        CustomMessage(message, "error", enqueueSnackbar);
        // handleClose()
      }
    }
  };
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      // CustomMessage(`ms${isMobile()}`, "error", enqueueSnackbar);

      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);

  const permissionFun = async (values) => {
    // CustomMessage(`mobile${values}`, "error", enqueueSnackbar);

    let tid = localStorage.getItem("TID");
    if (
      isInTeams() &&
      tid &&
      msClientId != "" &&
      permissionUpdatedFlag != 1 &&
      permissionUpdatedFlag != 3
    ) {
      // CustomMessage('inteams','error',enqueueSnackbar)
      window.open(
        `https://login.microsoftonline.com/${tid}/adminconsent?client_id=${msClientId}&scope=https://graph.microsoft.com/.default&redirect_uri=${redirecturl}`,
        "_blank"
      );
    }
    if (
      !isInTeams() &&
      tid &&
      msClientId != "" &&
      permissionUpdatedFlag != 1 &&
      permissionUpdatedFlag != 3
    ) {
      console.log("callled");
      setTimeout(() => {
        let childWindow = window.open(
          `https://login.microsoftonline.com/${tid}/adminconsent?client_id=${msClientId}&scope=https://graph.microsoft.com/.default&redirect_uri=${redirecturl}`,
          "_blank"
        );
        // handleShell();
        if (childWindow) {
          window.addEventListener("message", (event) => {
            // Handle the message received from the child window
            if (event.data.type === "flagMessage") {
              console.log("Received message from child window:", event.data);
              if (appPer == true) {
                console.log("Received message from child window:", event.data);
                botUpdate();
              }
              // flagUpdate();
            }
            //  else {
            //   CustomMessage(
            //     "Unable to add permissions",
            //     "error",
            //     enqueueSnackbar
            //   );
            // }
          });
        }
        // handleClose();
      }, 5000);
    }
    if (isMobile() && values == 0) {
      // CustomMessage('mobile admin consent', "error", enqueueSnackbar);

      window.location.href = `https://login.microsoftonline.com/${tid}/adminconsent?client_id=${values}&scope=https://graph.microsoft.com/.default&redirect_uri=${redirecturl}`;
      window.addEventListener("message", (event) => {
        if (event.data.type === "mobileFlagMessage") {
          console.log("Received mobile callback:", event.data);
          if (event.data.success && appPer === true) {
            botUpdate();
          } else {
            console.error("Mobile callback failed or incomplete:", event.data);
          }
        }
      });
    }
  };
  // } catch (error) {
  //   if (error.response) {
  //     const { message } = error.response.data;

  //     CustomMessage(message, "error", enqueueSnackbar);
  //   }
  // } finally {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }
  // }
  // };
  useEffect(() => {
    console.log("call fun");
    // CustomMessage(`have${permissionUpdatedFlag}${botFlag}`, "success", enqueueSnackbar);

    if (permissionUpdatedFlag == 0) {
      if (isMobile() && permissionUpdatedFlag == 0) {
        if (!botFlag) {
          getDetails();
        } else {
          botUpdate();
        }
      } else {
        console.log("else");

        permissionFun();
      }
    }
  }, []);
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "flagMessage" && isInTeams()) {
        console.log("Received message from child window:", event.data);
        if (appPer == true) {
          console.log("Received message from child window:", event.data);
          botUpdate();
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  return (
    <>
      {/* <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box style={{ textAlign: "center" }}>
              <img style={{ height: "50px", width: "auto" }} src={entraid} />
            </Box>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Azure Permission
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                sx={{ width: "100%", minWidth: "500px",marginBottom:'15px' }}
                variant="standard"
              >
                <BootstrapInput
                  placeholder="Object ID"
                  name="appObjectId"
                  id="appObjectId"
                  onChange={handleChange}
                  value={values.appObjectId}
                />
              </FormControl>
              <FormHelperText error={true}>
                {errors.appObjectId && touched.appObjectId
                  ? errors.appObjectId
                  : ""}
              </FormHelperText>
              <Box style={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  className="login-btn"
                  size="medium"

                  // onClick={permissionFun}
                >
                  <CustomLoader loading={loading} />
                  Submit
                </Button>
                <Button
                  type="submit"
                  sx={{marginTop:'20px !important'}}
                  className="login-btn3"
                  size="medium"
                  onClick={handleClose}

                >
                  CANCEL
                </Button>
              </Box>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div> */}
    </>
  );
}
