import React, { useEffect, useState } from "react";
import untitle from "../images/untitled-1-1.png";
import layerig from "../images/layer-1.svg";
import line2 from "../images/line-2.svg";
import group82 from "../images/group-82.png";
import group821 from "../images/group-82-1.png";
import polygon from "../images/polygon-2.svg";
import mockup from "../images/macbook-air-mockup-1-1.png";
import file1 from "../images/file-1.png";
import file2 from "../images/file-2.png";
import file3 from "../images/file-3.png";
import file4 from "../images/file-4.png";
import file5 from "../images/file-5.png";
import fr4 from "../images/frame-18-4.svg";
import fr1 from "../images/frame-18-1.svg";
import fr2 from "../images/frame-18-2.svg";
import fr3 from "../images/frame-18-3.svg";
import fr5 from "../images/frame-18-5.svg";
import ig3 from "../images/image-3.png";
import ig1 from "../images/image-1.png";
import object from "../images/objects.svg";
import logocontain from "../images/logo-container.svg";
import copyright from "../images/copyright-1.svg";
import down1 from "../images/download-1.png";
import { useNavigate } from "react-router";
import mailicon from "../images/main-icon.png";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import {
  DASHBOARD_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
} from "../constants/RoutePath";
import "./style.css"; // Import the new style.css
import {
  authHeader,
  authHeaderchk,
  CustomMessage,
  getSideFlag,
  getTok,
} from "./common/mainfunctions";
import * as microsoftTeams from "@microsoft/teams-js";
import { useSnackbar } from "notistack";
import { EnergySavingsLeaf } from "@mui/icons-material";
import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
export default function LandingPageTeams() {
  const navigate = useNavigate();
  const [idx, setidx] = useState(1);
  const images = [file1, file1, file2, file3, file4, file5];
  const [sideFlag, setSideFlag] = useState(false);
  const [mobFlag, setMobFlag] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  const isInTeams = () => {
    // This function checks if the app is running inside Microsoft Teams
    return window.self !== window.top;
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      // CustomMessage(
      //   `I am in mobile`,
      //   "error",
      //   enqueueSnackbar
      // );
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);

  // window.addEventListener(
  //   "message",
  //   function (event) {
  //     // Verify the origin to make sure the message is coming from the correct subdomain
  //     if (event.origin === "https://devo365tc3.devhrassist.app") {
  //       const token = event.data.token;

  //       if (token) {
  //         // Store the token in localStorage or sessionStorage
  //         localStorage.setItem("userToken", token);

  //         // Now you can handle redirection when the Get Started button is clicked
  //         document
  //           .getElementById("getStartedBtn")
  //           .addEventListener("click", function () {
  //             window.location.href = `https://devo365tc3.localhost:3000/signin?tokens=${token}`;
  //           });
  //       }
  //     }
  //   },
  //   false
  // );
  function getCookie(name) {
    console.log(name, document.cookie);
    const value = `; ${document.cookie}`;
    console.log(value);
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  //   const getStartFun = () => {
  //     const token = getCookie("userToken");
  //     const subdomain = getCookie("userdomain");
  //     console.log("sideFlag:", sideFlag);

  //     // Check if microsoftTeams object is available (indicates we're in Teams)
  //     if (window.microsoftTeams) {
  //         microsoftTeams.getContext((context) => {
  //             const isTeams = context && context.teamId;
  //             const isMobileTeams = context && context.hostClientType === "android" || context.hostClientType === "ios";

  //             if (isTeams || isMobileTeams) {

  //                 // If inside Teams (mobile or desktop), navigate to the Teams tab
  //                 console.log("Detected inside Microsoft Teams, mobile or desktop.");
  //                 microsoftTeams.executeDeepLink(
  //                     "https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/o365tool?webUrl=https://devhrassist.app/signup"
  //                 );
  //             } else if(mobFlag){

  //               microsoftTeams.executeDeepLink(
  //                 "https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/o365tool?webUrl=https://devhrassist.app/signup"
  //             );
  //             }
  //             // else {
  //             //     // Not inside Teams, fall back to the website flow
  //             //     handleWebsiteFlow(token, subdomain);
  //             // }
  //         });
  //     }
  //      else if(isTeamsMobile==true){

  //       microsoftTeams.executeDeepLink(
  //         "https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/o365tool?webUrl=https://devhrassist.app/signup"
  //     );
  //     }
  //     else {

  //         // If not inside Teams, handle website flow
  //         handleWebsiteFlow(token, subdomain);
  //     }
  // };

  // Separate logic for the website flow
  const handleWebsiteFlow = (token, subdomain) => {
    if (token) {
      // Redirect to the subdomain dashboard with the token
      // CustomMessage(`dashboard part`, "info", enqueueSnackbar);

      window.location.href = `https://${subdomain}.hrassist.app/dashboard`;
    }

    let t = localStorage.getItem("CURRENT");
    console.log(getTok(), authHeader(), t);

    if (t) {
      let url = `${t}/dashboard`;
      window.open(url, "_self");
    } else {
      let hostname = window.location.hostname;
      let subdom = hostname.split(".")[0]; // Extract subdomain
      console.log("Subdomain:", subdom);
      console.log("Hostname:", hostname);

      if (
        hostname !== "devhrassist.app" &&
        subdom !== "localhost" &&
        subdom !== "hrassist"
      ) {
        console.log("Valid subdomain, navigating...");
        if (authHeader()) {
          navigate(DASHBOARD_ROUTE);
        } else {
          navigate("/signin");
        }
      } else {
        // CustomMessage(`else part`, "info", enqueueSnackbar);

        navigate(SIGNUP_ROUTE);
      }
    }
  };
  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext((context) => {
      const contextString = JSON.stringify(context, null, 2)
      // CustomMessage(`con-${contextString.appId}`, "info", enqueueSnackbar);

      const isMobileClient =
        context.hostClientType === "ios" || context.hostClientType === "android";
  
      if (isMobileClient) {
        // CustomMessage("Navigating in mobile environment", "info", enqueueSnackbar);
        var tabInstance = {
          tabName: "App",
          entityId: "hrtab1",
      };
       
  function handleSuccess() {
      CustomMessage("Navigating success", "success", enqueueSnackbar);

  }
       
  function handleError(error) {
      console.error("Error during navigation:", error);
      CustomMessage(`err${error}`, "error", enqueueSnackbar);


  }
  // microsoftTeams.navigateToTab(tabInstance, handleSuccess, handleError);
      }
    });
  }, []);
  
  
  // useEffect(() => {
  //   microsoftTeams.initialize();
  //   microsoftTeams.getContext((context) => {
  //     const isMobileClient = context.hostClientType === "ios" || context.hostClientType === "android";
  //     localStorage.setItem("SIDE_FLAG", true);
  
  //     if (isMobileClient) {
  //       setMobFlag(true);
  //     }
  //   });
  // }, []);
  useEffect(() => {
    // Check if the app is running inside Microsoft Teams
    if (window.location !== window.parent.location) {
      setSideFlag(true);
      localStorage.setItem("SIDE_FLAG", true);
      microsoftTeams.initialize();
    } else {
      console.log("App is running outside of Microsoft Teams");
      localStorage.setItem("SIDE_FLAG", false);
      microsoftTeams.initialize();

    }
  }, []);
  

  const getStartFun = async () => {
    const token = getCookie("userToken");
    const subdomain = getCookie("userdomain");
    const firstTabDeepLink =
      "https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/app?webUrl=https://hrassist.app/entry";
     
      if (
        getSideFlag() == "true" ||
        getSideFlag() == true ||
        sideFlag == true ||
        sideFlag == "true"){
          microsoftTeams.executeDeepLink(
      "https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/app?webUrl=https://hrassist.app/entry"
          );
        }
        else {
          if (token && isMobile() == false) {
            // Redirect to the subdomain dashboard with the token
                  // CustomMessage(`dashboardtes`, "success", enqueueSnackbar);
            
            window.location.href = `https://${subdomain}.hrassist.app/dashboard`;

          }
          let t = localStorage.getItem("CURRENT");
          console.log(getTok(), authHeader(), t);
    
          if (t) {
            let url = `${t}/dashboard`;
            // localStorage.clear();
            window.open(url, "_self");
          } else {
            let hostname = window.location.hostname;
            let subdom = hostname.split(".")[0]; // Extract subdomain
            console.log("Subdomain:", subdom);
            console.log("Hostname:", hostname);
    
            if (
              hostname !== "devhrassist.app" &&
              subdom !== "localhost" &&
              subdom !== "hrassist"
            ) {
              console.log("Valid subdomain, navigating...");
              if (authHeader()) {
                navigate(DASHBOARD_ROUTE);
              } else {
                navigate("/signin");
              }
            } else {
      // CustomMessage(`signupteas`, "success", enqueueSnackbar);

              navigate(SIGNUP_ROUTE);
            }
          }
        }
      //       microsoftTeams.getContext((context) => {
      //     if (context.hostClientType === "ios" || context.hostClientType === "android") {
      //         microsoftTeams.navigateToTab({
      //             tabInstanceId: "app",
      //             entityId: "app",
      //         });
      //     }
      //      else {
      //         microsoftTeams.executeDeepLink(
      //           "https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/app?webUrl=https://hrassist.app/entry"
      //         );
      //     }
      // });
    microsoftTeams.getContext((context) => {
      const isMobileClient = context.hostClientType === "ios" || context.hostClientType === "android";
  
      if (isMobileClient) {
        // CustomMessage(`Navigating in mobile environment`, "info", enqueueSnackbar);
        microsoftTeams.executeDeepLink(firstTabDeepLink, (success) => {
          if (!success) {
            // CustomMessage(`Deep link failed, redirecting...`, "error", enqueueSnackbar);
            // window.location.href = "https://devhrassist.app/signup"; // Fallback
          }
        });
      } else if (token) {
        // CustomMessage(`Navigating in desktop environment`, "info", enqueueSnackbar);
        // window.location.href = `https://${subdomain}.hrassist.app/dashboard`;
      } 
    });
  };
  
  useEffect(() => {
    const token = getCookie("userToken");
    const subdomain = getCookie("userdomain");
    console.log(sideFlag, "sideflag");
    if (getSideFlag() == "true" || getSideFlag() == true) {
      console.log("i am mobile or teams");
    } else {
      if (token && token != undefined && isMobile() == false) {
        // Redirect to the subdomain dashboard with the token
        window.location.href = `https://${subdomain}.hrassist.app/dashboard`;
      }
      let t = localStorage.getItem("CURRENT");
      console.log(getTok(), authHeader(), t);

      if (t) {
        let url = `${t}/dashboard`;
        // localStorage.clear();
        // window.open(url, "_self");
      } else {
        let hostname = window.location.hostname;
        let subdom = hostname.split(".")[0]; // Extract subdomain
        console.log("Subdomain:", subdom);
        console.log("Hostname:", hostname);

        if (
          hostname !== "devhrassist.app" &&
          subdom !== "localhost" &&
          subdom !== "hrassist"
        ) {
          console.log("Valid subdomain, navigating...");
          if (authHeader()) {
            navigate(DASHBOARD_ROUTE);
          } else {
            // navigate("/signin");
          }
        }
      }
    }
  }, []);
  const idxfun = (id) => {
    console.log(id, "called");
    setidx(id);
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="dashboard-section">
        <img class="top-design" src={group82} />
        <img class="bottom-design" src={group821} />
        <img class="polygon-icon" src={polygon} />
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <p style={{ color: "#FFF", fontSize: "x-large" }}>
            👋 Welcome to HR Assist
          </p>
          <p className="main-heading">
            Streamline Employee Onboarding and <br />
            Offboarding with Ease
          </p>
          <img class="line-img" src={line2} />
          <p className="header-subtext">
            HR Assist empowers your HR team to manage user accounts, assign M365
            licenses, and update
            <br />
            employee data—all without IT involvement.
          </p>
          <button onClick={getStartFun} className="btns">
            Get Started
          </button>
          <img
            style={{ marginBottom: "-13px" }}
            className="dashboard-img"
            src={untitle}
          />
        </Box>
      </Box>
      <Box className="about-section">
        <Container>
          <Grid container alignItems="center" spacing={{ xs: 2, sm: 3, md: 5 }}>
            <Grid item xs={12} sm={12} md={6}>
              <Box className="about-ellipse">
                <img class="macbook-air" src={mockup} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box className="about-bg">ABOUT US</Box>
              <p className="ef-title">Efficient User Management</p>
              <p className="hr-text">
                HR Assist is a self-service tool designed to streamline the
                onboarding, updating, and offboarding of employees, providing HR
                teams with a quick and efficient way to manage user accounts.
                Integrated with Microsoft Entra ID, Pax8, and Intune, HR Assist
                ensures seamless user management and enhanced operational
                efficiency.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="feature-section">
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ margin: "auto" }} className="about-bg">
            Features
          </Box>
          <p className="ef-title">Key Features of HR Assist</p>
          <p className="hr-text">
            Explore how HR Assist simplifies HR tasks with seamless user
            management, automated license assignments,
            <br />
            and integrated workflows. Experience efficient, secure HR operations
            with our powerful tool.
          </p>
        </Box>
        <Container>
          <Grid container direction="row" sx={{ mt: 3 }} spacing={3}>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr4} />
                <p className="ft-box-title">Effortless User Management</p>
                <p className="ft-box-txt">
                  Easily create, update, and offboard employee accounts with
                  just a few clicks, streamlining your HR processes.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img class="frame-10" src={fr1} />
                <p className="ft-box-title">Seamless License Assignment</p>
                <p className="ft-box-txt">
                  Quickly assign and manage Microsoft 365 licenses to ensure all
                  employees have the tools they need from day one.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr2} />
                <p className="ft-box-title">Streamlined Distribution Lists</p>
                <p className="ft-box-txt">
                  Organize and manage distribution lists to facilitate effective
                  communication and collaboration within your organization.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr3} />
                <p className="ft-box-title">Approval-Based Workflows</p>
                <p className="ft-box-txt">
                  Implement secure, approval-based workflows to ensure
                  compliance and maintain control over HR processes.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr4} />
                <p className="ft-box-title">
                  Integrated with Microsoft Entra ID
                </p>
                <p className="ft-box-txt">
                  Enjoy seamless integration with your Microsoft Entra ID,
                  enhancing security and user management without creating
                  external accounts.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr5} />
                <p className="ft-box-title">Enhanced Security</p>
                <p className="ft-box-txt">
                  Leverage integrations with Microsoft Entra ID, Pax8, and
                  Intune for secure, efficient management of users and devices.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="screenshot-section">
        <Container>
          <Box sx={{ margin: "auto" }} className="about-bg">
            SCREENSHOTS
          </Box>
          <p className="ef-title">Explore HR Assist in Action</p>
          <p className="hr-text">
            Discover how HR Assist streamlines HR tasks with intuitive user
            management and seamless integrations. <br />
            Our screenshots highlight key features that enhance efficiency and
            simplify operations.
          </p>
          <Box>
            <TabContext value={value}>
              <Box
                sx={{
                  margin: "auto",
                  width: "100%",
                  bgcolor: "background.paper",
                  mt: 2,
                  maxWidth: "810px",
                  padding: "4px",
                }}
              >
                <TabList value={value} onChange={handleChange} centered>
                  <Tab label="Dashboard Overview" value="1" />
                  <Tab label="License Management" value="2" />
                  <Tab label="Request Actions" value="3" />
                  <Tab label="User Management" value="4" />
                  <Tab label="Request Submission" value="5" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <img src={file1} />
              </TabPanel>
              <TabPanel value="2">
                <img src={file2} />
              </TabPanel>
              <TabPanel value="3">
                <img src={file3} />
              </TabPanel>
              <TabPanel value="4">
                <img src={file4} />
              </TabPanel>
              <TabPanel value="5">
                <img src={file5} />
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          background: "#fff",
          height: { xs: "250px", sm: "250px", md: "400px" },
          mt: "-270px",
          display: { xs: "none", sm: "none", md: "block" },
        }}
      ></Box>
      <Box className="integration-section">
        <Container>
          <Box sx={{ textAlign: "center" }}>
            <Box sx={{ margin: "auto" }} className="about-bg">
              INTEGRATION
            </Box>
            <p className="ef-title">Integration Highlights</p>
            <p className="hr-text">
              HR Assist integrates with your Microsoft Entra ID, Pax8, and
              Intune for efficient user and software management. <br />
              Enjoy a unified platform for streamlined operations and effective
              device management.
            </p>
          </Box>
          <Grid sx={{ mt: 2 }} container spacing={2} direction="row">
            <Grid item xs={12} md={4}>
              <Box className="int-box">
                <img src={ig3} />
                <p className="int-title">Microsoft Entra ID</p>
                <p className="int-text">
                  Leverages Microsoft Entra ID for efficient identity and access
                  management.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="int-box">
                <img src={ig1} />
                <p className="int-title">Pax8 Integration</p>
                <p className="int-text">
                  Utilizes Pax8 for streamlined software and services
                  management.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="int-box">
                <img class="img-2" src={down1} />
                <p className="int-title">Intune Management</p>
                <p className="int-text">
                  Integrates with Intune to manage and secure devices, ensuring
                  compliance and protection.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box class="footer">
        <p className="ft-heading">Reach Out to HR Assist</p>
        <p className="ft-txt">
          Got a question or need assistance? Our team is ready to help. Reach
          out to us through the following contact details:
        </p>
        <img class="img-txt" src={object} alt="" />
        <div className="support-mail">
          <MarkunreadOutlinedIcon color="#fff" />
          <p className="ft-txt">help@intelligis.com</p>
        </div>
      </Box>
    </>
  );
}
