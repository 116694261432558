import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Input,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Logo from "../images/logo.jpg";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import MailIcon from "../images/mail.svg";
import PassIcon from "../images/password.svg";
import { Stack } from "@mui/system";
import * as Yup from "yup";
import API from "./common/API";
import { useFormik } from "formik";
import {
  CODE_AUTH_FOR_USER_URL,
  LOGIN_URL,
  MICROSOFT_AUTHENTICATION_URL,
  SUB_DOMAIN_VALID_URL,
  USER_PROFILE_URL,
} from "./common/ApiUrl";
import {
  CustomMessage,
  CustomMessageToast,
  getDomain,
  getIntegrate,
  getRole,
  getSideFlag,
} from "./common/mainfunctions";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DASHBOARD_ROUTE, SIGNUP_ROUTE } from "../constants/RoutePath";
import CustomLoader from "./common/CustomLoader";
import Facebook from "../images/facebook.svg";
import Twitter from "../images/twitter.svg";
import Linkedin from "../images/linkedin.svg";
import { useDispatch } from "react-redux";
import {
  MIC_AUTH_FLAG_TYPE,
  USER_DETAILS_FLAG,
} from "../redux/action/ActionType";
import { AzureAD, AuthenticationState } from "react-aad-msal";

import { authProvider } from "../authProvider";
import AuthentySuccess from "./common/AuthentySuccess";
import { useEffect } from "react";
import defaultlogo from "../images/company-23 1.png";
import notfound from "../images/notfound.svg";
import { getUserAuth, getUserDetails } from "../redux/action";
import * as microsoftTeams from "@microsoft/teams-js";
import sideLogo from "../images/logoname.png";
import microsoftLogo from "../images/Microsoft_icon.svg 1.svg";
import PermissionAlert from "./organizationSetting/Permissions/PermissionAlert";

export default function Login(props) {
  const { token } = props;
  let domain = getDomain();
  const [info, setInfo] = useState();
  const [siloading, setSILoading] = useState(false);
  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [upn, setUPN] = useState();
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [miFlag, setMiFlag] = useState(false);
  const [domainvalid, setDomainValid] = useState(false);
  const [profile, setProfile] = useState();
  const [token1, setToken] = useState();
  const [code, setCode] = useState();
  const para = useParams();
  const [mobFlag, setMobFlag] = useState(false);
  const [tok, setTok] = useState();
  const { id } = para;
  console.log("id", id);
  const [userName, setUserName] = useState();
  const queryParams = new URLSearchParams(location.search);
  const tokens = queryParams.get("tokens");
  const ccc = queryParams.get("code");
  console.log("tokens", ccc);
  const [sideFlag, setSideFlag] = useState(false);
  const [wayFlag, setWayFlag] = useState();
  const [perFlag, setPerFlag] = useState(false);
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  const isInTeams = () => {
    // This function checks if the app is running inside Microsoft Teams
    return window.self !== window.top;
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);
  useEffect(() => {
    setToken(tokens);
    console.log("session", sessionStorage.getItem("authTokenF"));
  }, [tokens]);
  let spldom = window.location.origin.split("//")[1];

  let sd = spldom.split(".")[0];
  const urlParams = new URLSearchParams(window.location.search);
  const subdomain = urlParams.get("subdomain");
  console.log(sd, subdomain);

  function getUrlParameterFromFragment(name) {
    const match = RegExp(`${name}=([^&]*)`).exec(location.state);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }

  let idToken;
  if (location.state) {
    // Get the ID token from the URL fragment
    idToken = getUrlParameterFromFragment("id_token");
    // setTok(idToken)
  }
  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email("Give valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const domainValidation = async () => {
    setLoading(true);
    const config = {
      method: "POST",
      url: SUB_DOMAIN_VALID_URL,
      data: {
        subDomain: subdomain ? subdomain : sd,
      },
    };
    try {
      const response = await API(config);
      const { status, data, token } = response.data;
      const { profilePath } = data;
      if (status == "Success") {
        setDomainValid(true);
        setProfile(profilePath);
        if (subdomain != "") {
          // handleLoginClick()
        }
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        // CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id || sd) {
      domainValidation();
    }
  }, [id, sd]);

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  // Listen for messages from the redirect.html page
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type === "tokenMessage") {
        // Assuming that the message contains the token

        setCode(data.token.split("::")[1]);
        let code = data.token.split("::")[1];
        console.log("code", code);

        const receivedToken = data.token.split("::")[0];
        const base64Url = receivedToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedData = JSON.parse(atob(base64));
        const userDisplayName = decodedData.preferred_username;
        let namesplit = decodedData.name;
        setUserName(namesplit);
        const tid = decodedData.tid;
        localStorage.setItem("TID", tid);
        setToken(receivedToken);
        setUPN(userDisplayName);
        // Respond back to the redirect.html page with a confirmation message
        event.source.postMessage({ type: "tokenReceived" }, event.origin);
      }
      if (data && data.type === "code") {
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  useEffect(() => {
    // Check if the app is running inside Microsoft Teams
    if (window.location !== window.parent.location) {
      setSideFlag(true);
      localStorage.setItem("SIDE_FLAG", true);
      microsoftTeams.initialize();
    } else {
      console.log("App is running outside of Microsoft Teams");
      localStorage.setItem("SIDE_FLAG", false);
      setSideFlag(false);
    }
  }, []);
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type == "error") {
        console.log("error requester");
        const popup = window.open("", "_blank", "width=500,height=600");
        if (popup) {
          // Set up a listener for postMessage
          window.addEventListener("message", (event) => {
            // Check the origin of the sender
            if (event.origin === "http://localhost:3000/") {
              const { token, type } = event.data;

              popup.close();
            }
          });

          popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                        response_type=id_token%20code
                        &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
                        &client_id=${cliendid}
                        &redirect_uri=${redirecturl}
                        &state=12345
                        &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                        &client_info=1
                        &x-client-SKU=MSAL.JS
                        &x-client-Ver=1.4.18
                        &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                        &response_mode=fragment`;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  const getMicAuthen = async () => {
    setSILoading(true);
    let us = userName.split(" ");
    const config = {
      method: "POST",
      url: MICROSOFT_AUTHENTICATION_URL,
      data: {
        emailId: upn,
        authToken: token1 || tokens,
        subDomain: window.location.hostname.split(".")[0],
        firstName: userName.split(" ")[0],
        // lastName: userName.split(" ")[1],
        lastName: us.slice(1).join(" "),
      },
    };
    try {
      const response = await API(config);
      const { status, data, token, message } = response.data;
      const { role, userId, subDomain } = data;
      if (status == "Success") {
        localStorage.setItem("AUTH_TOKEN", token);
        // document.cookie = `userToken=${token}; path=/;  SameSite=lax`;
        // Set a simple cookie without specifying a domain for localhost development
        // web working one
        if (getSideFlag() == false) {
          document.cookie = `userToken=${token}; path=/; domain=.hrassist.app; samesite=lax`;
          document.cookie = `userdomain=${subDomain}; path=/; domain=.hrassist.app; samesite=lax`;
        } else {
          document.cookie = `userToken=${token}; path=/; domain=.hrassist.app; SameSite=None; Secure`;
          document.cookie = `userdomain=${subDomain}; path=/; domain=.hrassist.app; SameSite=None; Secure`;
        }

        // prod
        // document.cookie = `userToken=${token}; path=/; domain=.hrassist.app; samesite=lax`;
        // document.cookie = `userdomain=${subDomain}; path=/; domain=.hrassist.app; samesite=lax`;

        // if (token) {
        //   const landingPageWindow = window.open('http://localhost:3000/', '_blank');

        //   landingPageWindow.onload = function() {
        //     landingPageWindow.postMessage({ token: token }, 'http://localhost:3000/');
        //   };
        // }
        localStorage.setItem("SIDE_FLAG", sideFlag);
        localStorage.setItem("ROLE", role);
        localStorage.setItem("USER_ID", userId);
        let dm = "/".concat(subDomain);
        sessionStorage.setItem("DOMAIN", dm);
        localStorage.setItem("SubDomain", dm);
        const options1 = {
          method: "GET",
          headers: { Authorization: token },
          url: USER_PROFILE_URL,
        };
        try {
          const response = await API(options1);
          if (response.status === 200) {
            const { status, data } = response.data;
            localStorage.setItem("ORGID", data.organizationId);
            console.log("role chk", role);
            if (
              role == "Admin" &&
              (data.msIntegrateFlag == parseInt(0) ||
                data.msIntegrateFlag == null ||
                data.botAppInstallFlag != true ||
                data.botAppInstallFlag == 0)
            ) {
              navigation("/organization");
            } else {
              navigation("/dashboard");
            }
            dispatch({
              type: USER_DETAILS_FLAG,
              payload: data,
            });
          }
        } catch (error) {
          if (error.response) {
            let { data } = error.response;
          }
        }
        dispatch(getUserDetails());
        if (role != "Admin") {
          dispatch(getUserAuth(code));
        }
        CustomMessage("Login successfull", "success", enqueueSnackbar);

        const options = {
          method: "GET",
          headers: { Authorization: token },
          url: CODE_AUTH_FOR_USER_URL,
          params: {
            tokenCode: code ? code : ccc,
          },
        };
        try {
          const resp = await API(options);
          console.log(resp);
        } catch (error) {
          console.error(error);
        }
        console.log("role", role, getIntegrate());
        setTimeout(() => {
          // if (role == "Admin" && (getIntegrate() == parseInt(0)||getIntegrate()==null)) {
          //   navigation("/organization");
          // } else {
          //   navigation("/dashboard");
          // }
        }, 1500);
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        // localStorage.clear();
        CustomMessage(data.message, "error", enqueueSnackbar);
      }
    } finally {
      // navigation(ORGANIZATION_SETTING_ROUTE);
      setSILoading(false);
    }
  };
  useEffect(() => {
    if (tokens) {
      console.log("get tok", tokens);
      let code = tokens.split("::")[1];
      console.log("code", code);
      setCode(code);
      const receivedToken = tokens.split("::")[0];
      const base64Url = receivedToken.split(".")[1];
      console.log("base64", base64Url);
      let hasDash = base64Url && base64Url.includes("-");
      let hasUnderscore = base64Url && base64Url.includes("_");

      if (hasDash || hasUnderscore) {
        base64Url = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      }
      // const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const decodedData = JSON.parse(atob(base64Url));
      const userDisplayName = decodedData.preferred_username;
      const tid = decodedData.tid;
      let namesplit = decodedData.name;
      setUserName(namesplit);
      localStorage.setItem("TID", tid);
      setToken(receivedToken);
      setUPN(userDisplayName);
    }
    if (token1 || (tokens && domainvalid == true && siloading == false)) {
      // CustomMessage("Authenticated successfull", "success", enqueueSnackbar);

      getMicAuthen();
    }
  }, [token1, tokens]);
  const handleLoginClick = (flag) => {
    console.log(flag);
    sessionStorage.setItem("WAY_FLAG", flag);
    setWayFlag(flag);
    if (isInTeams()) {
      // Initialize Microsoft Teams SDK only if inside Teams
      microsoftTeams.initialize((initErr) => {
        if (initErr) {
          console.error("Teams initialization error:", initErr);
          return;
        }
        console.log("Teams SDK initialized");

        // Now use Teams authentication flow
        microsoftTeams.authentication.authenticate({
          url: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
            response_type=id_token%20code
            &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
            &client_id=${cliendid}
            &redirect_uri=${redirecturl}
            &state=12345
            &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
            &client_info=1
            &response_mode=fragment`,
          width: 500,
          height: 600,
          successCallback: (result) => {
            console.log("Authentication successful:", result);
            // Handle the result (e.g., token)
          },
          failureCallback: (reason) => {
            console.error("Authentication failed:", reason);
          },
        });
      });
    } else if (mobFlag === true) {
      // Mobile flow: redirect-based authentication
      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
        response_type=id_token%20code
        &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
        &client_id=${cliendid}
        &redirect_uri=${redirecturl}
        &state=12345
        &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
        &client_info=1
        &response_mode=fragment`;
    } else {
      // Non-Teams desktop/web browser popup
      const popup = window.open("", "_blank", "width=500,height=600");

      // Set up a listener for postMessage
      window.addEventListener("message", (event) => {
        // Check the origin of the sender
        if (event.origin === "http://localhost:3000/") {
          // if (
          //   event.origin === "https://portal.devo365toolkit.com/"
          // ) {
          // Handle the message data
          const { token, type } = event.data;

          // Close the popup window if needed
          popup.close();

          // Do something with the token, e.g., log in the user
          // console.log("Received token:", token);
        }
      });

      popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
          response_type=id_token%20code
          &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send
          &client_id=${cliendid}
          &redirect_uri=${redirecturl}
          &state=12345
          &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
          &client_info=1
          &x-client-SKU=MSAL.JS
          &x-client-Ver=1.4.18
          &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
          &response_mode=fragment`;
    }
  };
  let fe = sessionStorage.getItem("Flag");

  // useEffect(() => {
  //   if (fe == 1) {
  //     handleLoginClick();
  //   }
  // }, [fe == 1]);
  return (
    <>
      {perFlag && <PermissionAlert open={perFlag} />}
      {domainvalid == true && !loading ? (
        <Box sx={{ flexGrow: 1 }} component={"form"} onSubmit={handleSubmit}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                className="bg-image"
              >
                <Stack direction="row">
                  <img
                    className="main-logo"
                    src={sideLogo}
                    alt="logo"
                    style={{
                      // hight: "60px",
                      // width: "80px",
                      margin: "auto",
                      marginTop: "20px",
                      // marginLeft: "80px",
                    }}
                  />
                </Stack>

                <Stack
                  className="social-icons"
                  sx={{ pt: "20px" }}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Typography className="copyright" variant="h4">
                    HR Assist © 2024
                  </Typography>
                  <Box></Box>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {profile ? (
                <Box
                  sx={{
                    textAlign: "left",
                    margin: "0px 0px 0px 39px",
                    paddingTop: { xs: "0px", md: "36px" },
                    width: "180px",
                    height: "180px",
                  }}
                >
                  <img
                    className="profile-img"
                    style={{
                      // paddingTop: "23px",
                      width: "180px",
                      height: "180px",
                    }}
                    src={profile}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                    paddingTop: "70px",
                  }}
                >
                  <Box className="logo-box2">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        textAlign: "center !important",
                        margin: "auto",
                        padding: "43px",
                      }}
                    >
                      <Box style={{ width: "180px", height: "180px" }}>
                        <img src={defaultlogo} />
                      </Box>
                      <Typography>Your Logo here</Typography>
                    </Stack>
                  </Box>
                </Box>
              )}
              <Box className="form-right">
                {/* <img style={{ paddingTop: "40px" }} src={Logo} /> */}
                <Typography className="welcome-text" variant="h5">
                  Welcome to HR Assist Portal
                </Typography>
                <Typography className="log-actions">
                  Please log in to continue.
                </Typography>

                {/* <Typography className="login-action" variant="body2">
                  You will need to sign in to your Microsoft 365 Tenant using
                  Global
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  Admin to Sign-in to your Account.
                </Typography>
                <Typography className="login-action" variant="body2">
                  You will be redirected to Microsoft 365 Tenant page to Connect
                  to your
                </Typography>
                <Typography
                  className="login-action"
                  sx={{ mb: "20px" }}
                  variant="body2"
                >
                  Global Admin Account
                </Typography> */}

                <Button
                  onClick={() => {
                    handleLoginClick("signin");
                  }}
                  style={{ width: "100% !important" }}
                  className="wit-txt"
                >
                  <Typography
                    className="signin-btn-text"
                    style={{ width: "100% !important" }}
                  >
                    <div
                      className="microsoft-signin-button"
                      style={{ justifyContent: "center" }}
                    >
                      {siloading && <CustomLoader loading={siloading} />}
                      <img
                        src={microsoftLogo}
                        alt="Microsoft logo"
                        className="microsoft-logo"
                      />

                      <span>SIGN IN WITH MICROSOFT</span>
                    </div>
                  </Typography>
                </Button>
                <Typography className="log-actions">
                  By continuing, you agree to the{" "}
                  <Link
                    href="https://www.intelligis.com/hrassist_termsofuse/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://www.intelligis.com/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : !loading ? (
        <div className="center-container">
          <div className="image-container centered-image">
            <img
              src={notfound}
              style={{ width: "100%" }}
              alt="Main Image"
              className="main-image centered-image"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
